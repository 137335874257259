import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dspList: [],
};

const dspSlice = createSlice({
  name: "dsp",
  initialState: {
    ...initialState,
  },
  reducers: {
    setDspList: (state, action) => {
      state.dspList = [...action.payload];
    },
    clearDspList: (state) => {
      state.dspList = [];
    },
  },
});

export default dspSlice.reducer;
export const { setDspList, clearDspList } = dspSlice.actions;
