import { useState } from "react";
import useAmpClient from "hooks/amp_api/useAmpApi";
import handleAPICall from "helpers/handleAPICall";
import {
  setWarehouseList as setWarehouseListAction,
  clearWarehouseList,
} from "./reducers/warehouse";
import { useDispatch, useSelector } from "react-redux";

const useWarehouse = () => {
  const apiClient = useAmpClient();
  const [pendingRequest, setPendingRequest] = useState(false);
  const dispatch = useDispatch();
  const warehouseList = useSelector((state) => state.warehouse.warehouseList);

  const setWarehouseList = async () => {
    setPendingRequest(true);
    try {
      const warehouseList = await handleAPICall(
        null,
        apiClient.listWarehouses,
        []
      );
      dispatch(setWarehouseListAction(warehouseList));
      setPendingRequest(false);
    } catch (error) {
      setPendingRequest(false);
      throw error;
    }
  };

  const createWarehouse = async (warehouseName, stateId) => {
    return await handleAPICall(setPendingRequest, apiClient.createWarehouse, [
      warehouseName,
      stateId,
    ]);
  };

  const updateWarehouse = async (
    warehouseId,
    warehouseName = null,
    stateId = null
  ) => {
    return await handleAPICall(setPendingRequest, apiClient.editWarehouse, [
      warehouseId,
      warehouseName,
      stateId,
    ]);
  };

  const deleteWarehouse = async (warehouseId) => {
    return await handleAPICall(setPendingRequest, apiClient.deleteWarehouse, [
      warehouseId,
    ]);
  };

  const clearState = () => {
    dispatch(clearWarehouseList());
  };

  return {
    pendingRequest,
    warehouseList,
    setWarehouseList,
    createWarehouse,
    updateWarehouse,
    deleteWarehouse,
    clearState,
  };
};

export default useWarehouse;
