import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

const ReportSelector = ({
  currentReport,
  onSelectReport,
  reportList,
  disabled = false,
}) => {
  const [value, setValue] = useState(currentReport);

  const handleChange = (event) => {
    setValue(event.target.value);
    onSelectReport(event.target.value);
  };
  const id = "report-selector";
  const label = "Report";
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth size="small">
        <InputLabel id={id}>{label}</InputLabel>
        <Select
          disabled={disabled}
          labelId={id}
          id={id}
          value={value}
          label={label}
          onChange={handleChange}
        >
          {reportList.map((report) => (
            <MenuItem key={report.id} value={report.id}>
              {report.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ReportSelector;
