import { useState } from "react";
import useAmpClient from "hooks/amp_api/useAmpApi";
import handleAPICall from "helpers/handleAPICall";
import {
  setSystemUserList as setSystemUserListAction,
  clearSystemUserList,
} from "./reducers/systemUser";
import { useDispatch, useSelector } from "react-redux";

const useSystemUser = () => {
  const apiClient = useAmpClient();
  const [pendingRequest, setPendingRequest] = useState(false);
  const dispatch = useDispatch();
  const systemUserList = useSelector(
    (state) => state.systemUser.systemUserList
  );

  const currentUser = useSelector((state) => state.user.userInformation);

  const setSystemUserList = async () => {
    setPendingRequest(true);
    try {
      const listFromAPI = await handleAPICall(null, apiClient.listUsers, []);
      const filteredList = listFromAPI.filter((x) => x.id !== currentUser.id);
      dispatch(setSystemUserListAction(filteredList));
      setPendingRequest(false);
    } catch (error) {
      setPendingRequest(false);
      throw error;
    }
  };

  const createSystemUser = async (
    email,
    name,
    lastName,
    roles,
    dspId = null
  ) => {
    return await handleAPICall(setPendingRequest, apiClient.createUser, [
      email,
      name,
      lastName,
      roles,
      dspId,
    ]);
  };

  const updateSystemUser = async (
    userId,
    name = null,
    lastName = null,
    dspId = null
  ) => {
    return await handleAPICall(setPendingRequest, apiClient.editUser, [
      userId,
      name,
      lastName,
      dspId,
    ]);
  };

  const deleteSystemUser = async (userId) => {
    return await handleAPICall(setPendingRequest, apiClient.deleteUser, [
      userId,
    ]);
  };

  const clearState = () => {
    dispatch(clearSystemUserList());
  };

  return {
    pendingRequest,
    systemUserList,
    setSystemUserList,
    createSystemUser,
    updateSystemUser,
    deleteSystemUser,
    clearState,
  };
};

export default useSystemUser;
