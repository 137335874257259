import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";

const Table = ({ columns, rows = [], pageSize = 10 }) => {
  const numColumns = columns.length;
  const finalColumns = columns.map((column) => {
    return {
      ...column,
      flex: 1 / numColumns,
    };
  });

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-toolbarContainer": {
      backgroundColor: theme.palette.primary.light,
    },
    "& .MuiButton-text": {
      color: theme.palette.primary.contrastText,
    },
    "& .MuiInput-root": {
      color: theme.palette.primary.contrastText,
      borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: theme.palette.primary.light,
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      color: theme.palette.primary.contrastText,
      fontWeight: 600,
    },
    ".ui-table__link": {
      color: theme.palette.primary.main,
    },
    ".ui-table__link:hover": {
      color: theme.palette.primary.light,
    },
  }));
  return (
    // <Box sx={{ height: 600, width: "100%" }}>
    <StyledDataGrid
      sx={{
        backgroundColor: "#fff",
        boxShadow: 2,
      }}
      rows={rows}
      columns={finalColumns}
      pageSize={pageSize}
      rowsPerPageOptions={[10]}
      autoHeight
      disableDensitySelector
      disableColumnSelector
      disableSelectionOnClick
      components={{ Toolbar: GridToolbar }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
    />
    // </Box>
  );
};

export default Table;
