import { useState } from "react";
import useAmpClient from "hooks/amp_api/useAmpApi";
import handleAPICall from "helpers/handleAPICall";
import {
  setUsaStateList as setUsaStateListAction,
  clearUsaStateList,
} from "./reducers/usaState";
import { useDispatch, useSelector } from "react-redux";

const useUsaState = () => {
  const apiClient = useAmpClient();
  const [pendingRequest, setPendingRequest] = useState(false);
  const dispatch = useDispatch();
  const usaStateList = useSelector((state) => state.usaState.usaStateList);

  const setUsaStateList = async () => {
    if (usaStateList.length === 0) {
      setPendingRequest(true);
      try {
        const listFromAPI = await handleAPICall(null, apiClient.listStates, []);
        dispatch(setUsaStateListAction(listFromAPI));
        setPendingRequest(false);
      } catch (error) {
        setPendingRequest(false);
        throw error;
      }
    }
  };

  const clearState = () => {
    dispatch(clearUsaStateList());
  };

  return {
    pendingRequest,
    usaStateList,
    setUsaStateList,
    clearState,
  };
};

export default useUsaState;
