import { Routes, Route } from "react-router-dom";
import {
  privateRoutes,
  regularUserRoutes,
  adminRoutes,
  adminDetailRoutes,
  routeMapper,
} from "./routes";

const PrivateRoutes = ({ userRole }) => {
  const privateViews = routeMapper(privateRoutes);

  let userSpecificViews = [];

  if (userRole === "regular_user") {
    userSpecificViews = routeMapper(regularUserRoutes);
  } else if (userRole === "admin") {
    userSpecificViews = routeMapper(adminRoutes.concat(adminDetailRoutes));
  }

  return <Routes>{privateViews.concat(userSpecificViews)}</Routes>;
};

export default PrivateRoutes;
