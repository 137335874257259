import { useState, useEffect, useRef } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import PowerBiContainer from "./PowerBiContainer";
import { Box } from "@mui/system";

import UseDashboard from "hooks/amp_api/useDashboards";
import useUser from "hooks/redux/useUser";
import Header from "components/Header/Header";
import Loader from "components/Loader/Loader";
import Alerts from "components/Alerts/Alerts";
import { useLocation } from "react-router-dom";

const Dashboard = () => {
  const [showAlert, setShowAlert] = useState(false);
  const { state } = useLocation();
  let alertMessage, alertType;
  if (state) {
    alertMessage = state.alertMessage;
    alertType = state.alertType;
  }

  const { user, actionsUser, pendingRequest } = useUser();
  const [timer, setTimer] = useState(0);
  const { pendingReport, getReportData } = UseDashboard();
  const [reportList, setReportList] = useState([]);
  const [currentReportInfo, setcurrenTReportInfo] = useState({});
  const reportRef = useRef(null);
  const userInfo = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => prev + 1);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (alertMessage) {
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentReportInfo) {
      const currentDate = Date.now();
      const expirationDate = Date.parse(
        currentReportInfo.embed_token_expires_on
      );
      if (expirationDate - currentDate <= 60000) {
        updateReportToken();
      }
    }
  }, [timer]);

  useEffect(() => {
    async function fetchData() {
      try {
        const resp = await actionsUser.getUserInfo(user.id);
        userInfo.current = resp;

        if (resp.dsp.reports.length > 0) {
          const currentReportId = resp.dsp.reports[0].id;
          const reportInfo = await getReportData(currentReportId);
          setReportList(resp.dsp.reports);
          setcurrenTReportInfo(reportInfo);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectReport = async (reportId) => {
    try {
      const reportInfo = await getReportData(reportId);
      setcurrenTReportInfo(reportInfo);
    } catch (error) {
      console.log(error);
    }
  };

  const updateReportToken = async () => {
    const reportInfo = await getReportData(currentReportInfo.id);
    setcurrenTReportInfo((prev) => {
      return {
        ...prev,
        embed_token_expires_on: reportInfo.embed_token_expires_on,
        embed_token: reportInfo.embed_token,
      };
    });
    reportRef.current.setAccessToken(reportInfo.embed_token);
  };

  const dashboardBody = (
    <Container>
      <Alerts
        open={showAlert}
        handleClose={() => {
          window.history.replaceState({}, document.title);
          setShowAlert(false);
        }}
        alertMessage={alertMessage}
        alertType={alertType}
      />
      <CssBaseline />
      <Header
        reportSelectorProps={{
          disabled: pendingReport,
          reportList: reportList,
          currentReport: currentReportInfo.id,
          onSelectReport: (reportId) => handleSelectReport(reportId),
        }}
      />
      <Box sx={{ height: 800 }}>
        {currentReportInfo.id ? (
          <PowerBiContainer
            reportReference={reportRef}
            embedUrl={currentReportInfo.embed_url}
            embedToken={currentReportInfo.embed_token}
            reportId={currentReportInfo.id}
          />
        ) : (
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              No Reports Available
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
  return (
    <Container>
      {(pendingReport && !currentReportInfo.id) || pendingRequest ? (
        <Loader open={true} />
      ) : (
        dashboardBody
      )}
    </Container>
  );
};

export default Dashboard;
