import { useState } from "react";
import useAmpClient from "./useAmpApi";

const UseDashboard = () => {
  const { getReport } = useAmpClient();
  const [pendingReport, setPendingReport] = useState(false);

  const getReportData = async (reportId) => {
    setPendingReport(true);
    try {
      const response = await getReport(reportId);
      setPendingReport(false);
      return response;
    } catch (error) {
      setPendingReport(false);
      throw error;
    }
  };

  return {
    pendingReport,
    getReportData,
  };
};

export default UseDashboard;
