import React, { useEffect, useState } from "react";
import { Paper, Button, TextField, Autocomplete } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import Loader from "components/Loader/Loader";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import CustomDialog from "components/CustomDialog/CustomDialog";

import { useParams, Link, useNavigate } from "react-router-dom";

import AdminContainer from "components/AdminContainer/AdminContainer";
import useDsp from "hooks/redux/useDsp";
import useSystemUser from "hooks/redux/useSystemUser";

const SystemUserDetail = () => {
  const parentRoute = "/system_user";
  const navigate = useNavigate();
  const goBack = () => {
    navigate(parentRoute);
  };
  const { pendingRequest, dspList, setDspList } = useDsp();
  const {
    pendingRequest: userRequest,
    systemUserList,
    setSystemUserList,
    updateSystemUser,
    deleteSystemUser,
  } = useSystemUser();

  const [enableEdit, setEnableEdit] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const userPlaceholder = {
    id: "",
    email: "",
    user_name: "",
    user_last_name: "",
    dsp: null,
    roles: [],
  };

  const [currentUser, setCurrentUser] = useState(userPlaceholder);

  const [cachedUser, setCachedUser] = useState(userPlaceholder);

  const { id } = useParams();

  useEffect(() => {
    async function initializeData() {
      await setDspList();
    }
    async function initializeSystemUsers() {
      await setSystemUserList();
    }
    if (dspList.length === 0) {
      initializeData();
    }
    if (systemUserList.length === 0) {
      initializeSystemUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (systemUserList.length > 0) {
      const user = systemUserList.find((user) => user.id === id);
      if (user !== undefined) {
        setCurrentUser(user);
        setCachedUser(user);
      } else {
        setCurrentUser({
          id: "invalid",
          email: "",
          user_name: "",
          user_last_name: "",
          dsp: null,
          roles: [],
        });
      }
    }
  }, [systemUserList, id]);

  const pageTitle =
    currentUser !== undefined
      ? `User: ${cachedUser.user_name} ${cachedUser.user_last_name}`
      : "User:";

  const handleChange = (name, value) => {
    let current = { ...currentUser };
    current[name] = value;
    setCurrentUser(current);
  };

  const handleEdit = () => {
    setEnableEdit(!enableEdit);
    if (enableEdit) {
      setCurrentUser(cachedUser);
    } else {
      setCachedUser(currentUser);
    }
  };

  const handleSave = () => {
    setSaveDialogOpen(true);
  };

  const handleConfirmSave = async () => {
    let indexMessage = {
      state: {
        alertMessage: `User ${currentUser.email} updated successfully`,
        alertType: "success",
      },
    };
    try {
      await updateSystemUser(
        currentUser.id,
        currentUser.name,
        currentUser.user_last_name,
        currentUser.dsp.id
      );
      setCachedUser(currentUser);
      setEnableEdit(false);
      setSaveDialogOpen(false);
      navigate(parentRoute, indexMessage);
    } catch (error) {
      indexMessage.state = {
        alertMessage: `User ${cachedUser.email} could not be updated`,
        alertType: "error",
      };
      setCurrentUser(cachedUser);
      setEnableEdit(false);
      setSaveDialogOpen(false);
      navigate(parentRoute, indexMessage);
    }
  };

  const handleConfirmDetele = async () => {
    let indexMessage = {
      state: {
        alertMessage: `User ${currentUser.email} deleted successfully`,
        alertType: "success",
      },
    };
    try {
      await deleteSystemUser(currentUser.id);
      setEnableEdit(false);
      setDeleteDialogOpen(false);
      navigate(parentRoute, indexMessage);
    } catch (error) {
      indexMessage.state = {
        alertMessage: `User ${currentUser.email} could not be deleted`,
        alertType: "error",
      };
      setEnableEdit(false);
      setDeleteDialogOpen(false);
      navigate(parentRoute, indexMessage);
    }
  };

  const dialogEdit = CustomDialog({
    open: saveDialogOpen,
    title: "Edit User",
    body: (
      <>
        <Typography variant="body1">
          Are you sure you want to save the changes?
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          Name:
        </Typography>
        <Typography variant="body1">
          {currentUser.user_name !== "" ? currentUser.user_name : ""}
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          Last Name:
        </Typography>
        <Typography variant="body1">
          {currentUser.user_last_name !== "" ? currentUser.user_last_name : ""}
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          DSP:
        </Typography>
        <Typography variant="body1">
          {currentUser.dsp !== null ? currentUser.dsp.name : ""}
        </Typography>
      </>
    ),
    confirmText: "Save",
    onConfirm: handleConfirmSave,
    onCancel: () => {
      setSaveDialogOpen(false);
    },
  });

  const dialogDelete = CustomDialog({
    open: deleteDialogOpen,
    title: "Delete User",
    body: (
      <>
        <Typography variant="body1">
          Are you sure you want to delete the User?
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          Name:
        </Typography>
        <Typography variant="body1">
          {currentUser.user_name !== "" ? currentUser.user_name : ""}
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          Last Name:
        </Typography>
        <Typography variant="body1">
          {currentUser.user_last_name !== "" ? currentUser.user_last_name : ""}
        </Typography>
      </>
    ),
    confirmText: "Delete",
    onConfirm: handleConfirmDetele,
    onCancel: () => {
      setDeleteDialogOpen(false);
    },
  });

  const saveEnabled =
    currentUser.user_name !== "" &&
    currentUser.user_last_name !== null &&
    currentUser.dsp !== null;

  const userHasDSP = !currentUser.roles.includes("admin");
  const userForm = (
    <Grid container spacing={5}>
      {dialogEdit}
      {dialogDelete}
      <Grid
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid sx={{ order: { xs: 1 } }}>
          <Button onClick={goBack} variant="text" startIcon={<ArrowBackIcon />}>
            Go Back
          </Button>
        </Grid>
        <Grid container columnSpacing={2} sx={{ order: { xs: 2 } }}>
          {!enableEdit ? (
            <Grid>
              <Button
                onClick={handleEdit}
                variant="contained"
                color="primary"
                endIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Grid>
          ) : (
            <>
              <Grid>
                <Button
                  onClick={handleEdit}
                  variant="contained"
                  color="grey"
                  endIcon={<CancelIcon />}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid>
                <Button
                  disabled={!saveEnabled}
                  onClick={handleSave}
                  variant="contained"
                  color="success"
                  endIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </Grid>
            </>
          )}

          <Grid>
            <Button
              onClick={() => {
                setDeleteDialogOpen(true);
              }}
              variant="contained"
              color="error"
              endIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid rowSpacing={4} container xs={12}>
        <Paper sx={{ paddingRight: 2, paddingLeft: 2, width: "100%" }}>
          <Grid container>
            <Grid xs={6}>
              <Typography sx={{ fontWeight: 600 }} variant="body1">
                Email:
              </Typography>
              <Typography variant="body1">{currentUser.email}</Typography>
            </Grid>
            <Grid xs={6}>
              <Typography sx={{ fontWeight: 600 }} variant="body1">
                User Type:
              </Typography>
              <Typography variant="body1">
                {currentUser.roles.join(", ")}
              </Typography>
            </Grid>
            <Grid xs={6}>
              <TextField
                required
                sx={{ marginBottom: 0, marginTop: 2 }}
                fullWidth
                error={currentUser?.user_name === ""}
                margin="normal"
                label="Name"
                id="name"
                disabled={!enableEdit}
                value={currentUser.user_name}
                onChange={(e) => handleChange("user_name", e.target.value)}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                required
                sx={{ marginBottom: 0, marginTop: 2 }}
                fullWidth
                error={currentUser?.user_last_name === ""}
                margin="normal"
                label="Last Name"
                id="name"
                disabled={!enableEdit}
                value={currentUser.user_last_name}
                onChange={(e) => handleChange("user_last_name", e.target.value)}
              />
            </Grid>
            {userHasDSP && (
              <Grid xs={6}>
                <Autocomplete
                  disabled={!enableEdit}
                  fullWidth
                  sx={{ marginBottom: 0, marginTop: 2 }}
                  disablePortal
                  id="dsp-select"
                  options={dspList}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={currentUser?.dsp === null}
                      label="DSP"
                    />
                  )}
                  value={currentUser.dsp}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(e, value) => handleChange("dsp", value)}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );

  const invalidId = (
    <Grid xs={12} container rowSpacing={5}>
      <Grid xs={12} justifyContent="space-between" alignItems="center">
        <Grid>
          <Typography variant="h4">Invalid User ID</Typography>
        </Grid>
        <Typography variant="body1">
          Please check the URL and try again. (ID: {id})
        </Typography>
        <Typography variant="body1">
          <Link to={parentRoute}>Go back to User list</Link>
        </Typography>
      </Grid>
    </Grid>
  );

  const content = currentUser.id === "invalid" ? invalidId : userForm;
  return (
    <AdminContainer title={pageTitle}>
      {pendingRequest || userRequest || currentUser.id === "" ? (
        <Loader open />
      ) : (
        content
      )}
    </AdminContainer>
  );
};

export default SystemUserDetail;
