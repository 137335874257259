import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
// import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";

import { Link, useNavigate, useLocation } from "react-router-dom";
import AccountMenu from "../AccountMenu/AccountMenu";
import ReportSelector from "../ReportSelector/ReportSelector";

const Header = ({ reportSelectorProps }) => {
  const location = useLocation();
  const parentRoute = "/";
  const navigate = useNavigate();
  const goBack = () => {
    navigate(parentRoute);
  };

  const headerStyles = {
    wrapper: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      borderBottom: 1,
      borderColor: "divider",
      justifyContent: "space-between",
    },
    menuItems: {
      display: "flex",
      alignSelf: "center",
      alignItems: "center",
    },
  };
  const logo = (
    <img style={{ width: 100, height: 50 }} src="/amp-logo.png" alt="logo" />
  );
  return (
    <React.Fragment>
      <Toolbar sx={headerStyles.wrapper}>
        <Box>
          <Link to="/">{logo}</Link>
        </Box>
        <Box sx={headerStyles.menuItems}>
          {reportSelectorProps && reportSelectorProps.reportList.length > 0 ? (
            <ReportSelector {...reportSelectorProps} />
          ) : location.pathname !== parentRoute ? (
            <Button
              onClick={goBack}
              variant="text"
              startIcon={<ArrowBackIcon />}
            >
              Go Back
            </Button>
          ) : null}
          <AccountMenu />
        </Box>
      </Toolbar>
    </React.Fragment>
  );
};

export default Header;
