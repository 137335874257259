import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alerts from "components/Alerts/Alerts";

import Copyright from "components/Copyright/Copyright";
import useLogin from "hooks/redux/useLogin";

import { publicRoutes } from "navigation/routes";

const ForgotPassword = () => {
  const { actionsLogin, pendingRequest } = useLogin();
  const navigate = useNavigate();

  const [alert, setAlert] = useState(false);
  const [alertType, setAlertType] = useState("error");
  const [alertMessage, setAlertMessage] = useState("error");

  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };

  useEffect(() => {
    if (!pendingRequest && email !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [pendingRequest, email]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await actionsLogin.forgotPassword(email);
      setAlertType("success");
      setAlertMessage(response.message);
      setAlert(true);
      setTimeout(() => {
        navigate(publicRoutes.signIn.path, { replace: true });
      }, 3500);
    } catch (error) {
      setAlertType("error");
      setAlertMessage(error.apiMsg);
      setAlert(true);
    }
  };
  const logo = (
    <img
      style={{ height: 100, marginBottom: 20 }}
      src="/amp-logo.png"
      alt="logo"
    />
  );
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {logo}
        <Typography component="h1" variant="h6">
          Forgot your password?
        </Typography>
        <Box sx={{ marginTop: 1 }}>
          <Typography variant="body1">
            We will send you and email to recover it
          </Typography>
        </Box>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={disabled}
          >
            Send email
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
      <Alerts
        open={alert}
        handleClose={handleClose}
        alertMessage={alertMessage}
        alertType={alertType}
      />
    </Container>
  );
};

export default ForgotPassword;
