import React, { useEffect, useState } from "react";
import { Paper, Button, TextField, Autocomplete } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import Loader from "components/Loader/Loader";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import CustomDialog from "components/CustomDialog/CustomDialog";

import { useParams, Link, useNavigate } from "react-router-dom";

import AdminContainer from "components/AdminContainer/AdminContainer";
import useWarehouse from "hooks/redux/useWarehouse";
import useUsaState from "hooks/redux/useUsaState";

const WarehouseCreate = () => {
  const fatherRoute = "/warehouse";
  const navigate = useNavigate();
  const goBack = () => {
    navigate(fatherRoute);
  };
  const { createWarehouse, pendingRequest: warehouseRequest } = useWarehouse();
  const {
    pendingRequest: usaStateRequest,
    usaStateList,
    setUsaStateList,
  } = useUsaState();

  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const warehousePlaceholder = {
    id: "",
    name: "",
    state: null,
  };

  const [currentWarehouse, setCurrentWarehouse] = useState(
    warehousePlaceholder
  );

  useEffect(() => {
    async function initializeUsaState() {
      await setUsaStateList();
    }
    if (usaStateList.length === 0) {
      initializeUsaState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageTitle = "Create Warehouse";

  const handleChange = (name, value) => {
    let current = { ...currentWarehouse };
    current[name] = value;
    setCurrentWarehouse(current);
  };

  const handleSave = () => {
    setSaveDialogOpen(true);
  };

  const handleConfirmSave = async () => {
    let indexMessage = {
      state: {
        alertMessage: `Warehouse ${currentWarehouse.name} created successfully`,
        alertType: "success",
      },
    };
    try {
      await createWarehouse(currentWarehouse.name, currentWarehouse.state.id);
      setSaveDialogOpen(false);
      navigate(fatherRoute, indexMessage);
    } catch (error) {
      indexMessage.state = {
        alertMessage: `Warehouse ${currentWarehouse.name} could not be created`,
        alertType: "error",
      };
      setSaveDialogOpen(false);
      navigate(fatherRoute, indexMessage);
    }
  };

  const dialogSave = CustomDialog({
    open: saveDialogOpen,
    title: "Create new Warehouse",
    body: (
      <>
        <Typography variant="body1">
          Are you sure you want to create this Warehouse?
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          Name:
        </Typography>
        <Typography variant="body1">
          {currentWarehouse.name !== "" ? currentWarehouse.name : ""}
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          State:
        </Typography>
        <Typography variant="body1">
          {currentWarehouse.state !== null ? currentWarehouse.state.name : ""}
        </Typography>
      </>
    ),
    confirmText: "Create",
    onConfirm: handleConfirmSave,
    onCancel: () => {
      setSaveDialogOpen(false);
    },
  });

  const saveEnabled =
    currentWarehouse.name !== "" && currentWarehouse.state !== null;

  const warehouseForm = (
    <Grid container spacing={5}>
      {dialogSave}
      <Grid
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid sx={{ order: { xs: 1 } }}>
          <Button onClick={goBack} variant="text" startIcon={<ArrowBackIcon />}>
            Go Back
          </Button>
        </Grid>
        <Grid container columnSpacing={2} sx={{ order: { xs: 2 } }}>
          <Grid>
            <Button
              disabled={!saveEnabled}
              onClick={handleSave}
              variant="contained"
              color="success"
              endIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid rowSpacing={2} container xs={12}>
        <Paper sx={{ paddingRight: 2, paddingLeft: 2, width: "100%" }}>
          <Grid container>
            <Grid xs={6}>
              <TextField
                required
                sx={{ marginBottom: 0, marginTop: 2 }}
                fullWidth
                error={currentWarehouse?.name === ""}
                margin="normal"
                label="Warehouse Name"
                id="warehouse-name"
                value={currentWarehouse.name}
                onChange={(e) => handleChange("name", e.target.value)}
              />
            </Grid>
            <Grid xs={6}>
              <Autocomplete
                fullWidth
                sx={{ marginBottom: 0, marginTop: 2 }}
                disablePortal
                id="usastate-select"
                options={usaStateList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={currentWarehouse?.state === null}
                    label="State"
                  />
                )}
                value={currentWarehouse.state}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, value) => handleChange("state", value)}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );

  const content = warehouseForm;
  return (
    <AdminContainer title={pageTitle}>
      {usaStateRequest || warehouseRequest ? <Loader open /> : content}
    </AdminContainer>
  );
};

export default WarehouseCreate;
