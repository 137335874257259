import axios from "axios";

export const HttpClient = (
  baseURL,
  requestInterceptor = (request) => request,
  responseInterceptor = (response) => response.data,
  errorHandler = (error) => Promise.reject(error)
) => {
  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.request.use(requestInterceptor, errorHandler);
  instance.interceptors.response.use(responseInterceptor, errorHandler);

  const get = (path, params = {}, config = {}) =>
    instance.get(path, { params, ...config });
  const post = (path, data = {}, config = {}) =>
    instance.post(path, data, config);
  const put = (path, data = {}, config = {}) =>
    instance.put(path, data, config);
  const del = (path, data = {}, config = {}) =>
    instance.delete(path, { data, ...config });

  return {
    get,
    post,
    put,
    del,
  };
};
