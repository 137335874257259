import React, { useEffect, useState } from "react";
import { Paper, Button, TextField, Autocomplete } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import Loader from "components/Loader/Loader";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import CustomDialog from "components/CustomDialog/CustomDialog";

import { useParams, Link, useNavigate } from "react-router-dom";

import Table from "components/Table/Table";
import AdminContainer from "components/AdminContainer/AdminContainer";
import useDsp from "hooks/redux/useDsp";
import useWarehouse from "hooks/redux/useWarehouse";

const DspCreate = () => {
  const fatherRoute = "/";
  const navigate = useNavigate();
  const goBack = () => {
    navigate(fatherRoute);
  };
  const { createDsp, pendingRequest: dspRequest } = useDsp();
  const {
    pendingRequest: warehouseRequest,
    warehouseList,
    setWarehouseList,
  } = useWarehouse();

  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const dspPlaceholder = {
    id: "",
    name: "",
    warehouse: null,
    powerbi_group_id: "",
    reports: [],
  };

  const [currentDsp, setCurrentDsp] = useState(dspPlaceholder);

  useEffect(() => {
    async function initializeWarehouse() {
      await setWarehouseList();
    }
    if (warehouseList.length === 0) {
      initializeWarehouse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageTitle = "Create DSP";

  const handleChange = (name, value) => {
    let current = { ...currentDsp };
    current[name] = value;
    setCurrentDsp(current);
  };

  const handleSave = () => {
    setSaveDialogOpen(true);
  };

  const handleConfirmSave = async () => {
    let indexMessage = {
      state: {
        alertMessage: `Dsp ${currentDsp.name} created successfully`,
        alertType: "success",
      },
    };
    try {
      await createDsp(currentDsp.name, currentDsp.warehouse.id);
      setSaveDialogOpen(false);
      navigate(fatherRoute, indexMessage);
    } catch (error) {
      indexMessage.state = {
        alertMessage: `Dsp ${currentDsp.name} could not be created`,
        alertType: "error",
      };
      setSaveDialogOpen(false);
      navigate(fatherRoute, indexMessage);
    }
  };

  const dialogSave = CustomDialog({
    open: saveDialogOpen,
    title: "Create new DSP",
    body: (
      <>
        <Typography variant="body1">
          Are you sure you want to create this DSP?
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          Name:
        </Typography>
        <Typography variant="body1">
          {currentDsp.name !== "" ? currentDsp.name : ""}
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          Warehouse:
        </Typography>
        <Typography variant="body1">
          {currentDsp.warehouse !== null ? currentDsp.warehouse.name : ""}
        </Typography>
      </>
    ),
    confirmText: "Create",
    onConfirm: handleConfirmSave,
    onCancel: () => {
      setSaveDialogOpen(false);
    },
  });

  const saveEnabled = currentDsp.name !== "" && currentDsp.warehouse !== null;

  const dspForm = (
    <Grid container spacing={5}>
      {dialogSave}
      <Grid
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid sx={{ order: { xs: 1 } }}>
          <Button onClick={goBack} variant="text" startIcon={<ArrowBackIcon />}>
            Go Back
          </Button>
        </Grid>
        <Grid container columnSpacing={2} sx={{ order: { xs: 2 } }}>
          <Grid>
            <Button
              disabled={!saveEnabled}
              onClick={handleSave}
              variant="contained"
              color="success"
              endIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid rowSpacing={2} container xs={12}>
        <Paper sx={{ paddingRight: 2, paddingLeft: 2, width: "100%" }}>
          <Grid container>
            <Grid xs={6}>
              <TextField
                required
                sx={{ marginBottom: 0, marginTop: 2 }}
                fullWidth
                error={currentDsp?.name === ""}
                margin="normal"
                label="DSP Name"
                id="dsp-name"
                value={currentDsp.name}
                onChange={(e) => handleChange("name", e.target.value)}
              />
            </Grid>
            <Grid xs={6}>
              <Autocomplete
                fullWidth
                sx={{ marginBottom: 0, marginTop: 2 }}
                disablePortal
                id="warehouse-select"
                options={warehouseList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={currentDsp?.warehouse === null}
                    label="Warehouse"
                  />
                )}
                value={currentDsp.warehouse}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, value) => handleChange("warehouse", value)}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );

  const content = dspForm;
  return (
    <AdminContainer title={pageTitle}>
      {warehouseRequest || dspRequest ? <Loader open /> : content}
    </AdminContainer>
  );
};

export default DspCreate;
