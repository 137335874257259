import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  role: "",
  id: "",
};

const userSlice = createSlice({
  name: "user",
  initialState: {
    userInformation: { ...initialState },
  },
  reducers: {
    saveUserInformation: (state, action) => {
      state.userInformation = { ...action.payload.userInformation };
    },
    removeUserInformation: (state) => {
      state.userInformation = { ...initialState };
    },
  },
});

export default userSlice.reducer;
export const { saveUserInformation, removeUserInformation } = userSlice.actions;
