import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Table from "components/Table/Table";
import AdminContainer from "components/AdminContainer/AdminContainer";
import Alerts from "components/Alerts/Alerts";
import useDsp from "hooks/redux/useDsp";
import Loader from "components/Loader/Loader";
import Grid from "@mui/material/Unstable_Grid2";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const Dsp = () => {
  const createRoute = "/dsp/create";
  const navigate = useNavigate();
  const create = () => {
    navigate(createRoute);
  };
  const { pendingRequest, setDspList, dspList } = useDsp();
  const [requestSent, setRequestSent] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { state } = useLocation();
  let alertMessage, alertType;
  if (state) {
    alertMessage = state.alertMessage;
    alertType = state.alertType;
  }

  const columns = [
    {
      field: "name",
      headerName: "DSP Name",
      renderCell: (params) => (
        <Link className={"ui-table__link"} to={`/dsp/${params.row.id}`}>
          {params.value}
        </Link>
      ),
    },
    {
      field: "warehouse",
      headerName: "Warehouse",
      valueGetter: (params) => params.row.warehouse.name,
    },
    {
      field: "state",
      headerName: "State",
      valueGetter: (params) => params.row.warehouse.state.name,
    },
    {
      field: "reports",
      headerName: "Number of Reports",
      type: "number",
      valueGetter: (params) =>
        params.row.reports ? params.row.reports.length : 0,
    },
  ];

  useEffect(() => {
    async function initializeData() {
      await setDspList();
      setRequestSent(true);
    }
    initializeData();
    if (alertMessage) {
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = (
    <Grid rowSpacing={2} container>
      <Alerts
        open={showAlert}
        handleClose={() => {
          window.history.replaceState({}, document.title);
          setShowAlert(false);
        }}
        alertMessage={alertMessage}
        alertType={alertType}
      />
      <Grid alingItems="end" container xs={12}>
        <Grid xsOffset={"auto"}>
          <Button
            onClick={create}
            variant="contained"
            color="primary"
            endIcon={<AddIcon />}
          >
            Create DSP
          </Button>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Table columns={columns} rows={dspList} />
      </Grid>
    </Grid>
  );

  return (
    <AdminContainer title="DSP">
      {!requestSent || pendingRequest ? <Loader open={true} /> : content}
    </AdminContainer>
  );
};

export default Dsp;
