import React, { useEffect, useState } from "react";
import { Paper, Button, TextField, Autocomplete } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import Loader from "components/Loader/Loader";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import CustomDialog from "components/CustomDialog/CustomDialog";

import { useParams, Link, useNavigate } from "react-router-dom";

import Table from "components/Table/Table";
import AdminContainer from "components/AdminContainer/AdminContainer";
import useDsp from "hooks/redux/useDsp";
import useWarehouse from "hooks/redux/useWarehouse";

const DspDetail = () => {
  const fatherRoute = "/";
  const navigate = useNavigate();
  const goBack = () => {
    navigate(fatherRoute);
  };
  const {
    pendingRequest,
    setDspList,
    updateDsp,
    deleteDsp,
    dspList,
  } = useDsp();
  const {
    pendingRequest: warehouseRequest,
    warehouseList,
    setWarehouseList,
  } = useWarehouse();

  const [enableEdit, setEnableEdit] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const dspPlaceholder = {
    id: "",
    name: "",
    warehouse: null,
    powerbi_group_id: "",
    reports: [],
  };

  const [currentDsp, setCurrentDsp] = useState(dspPlaceholder);

  const [cachedDsp, setCachedDsp] = useState(dspPlaceholder);

  const { id } = useParams();

  useEffect(() => {
    async function initializeData() {
      await setDspList();
    }
    async function initializeWarehouse() {
      await setWarehouseList();
    }
    if (dspList.length === 0) {
      initializeData();
    }
    if (warehouseList.length === 0) {
      initializeWarehouse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dspList.length > 0) {
      const dsp = dspList.find((dsp) => dsp.id === id);
      if (dsp !== undefined) {
        setCurrentDsp(dsp);
        setCachedDsp(dsp);
      } else {
        setCurrentDsp({
          id: "invalid",
          name: "",
          warehouse: null,
          powerbi_group_id: "",
          reports: [],
        });
      }
    }
  }, [dspList, id]);

  const saveEnabled = currentDsp.name !== "" && currentDsp.warehouse !== null;

  const pageTitle =
    currentDsp !== undefined && currentDsp.name
      ? `DSP : ${cachedDsp.name}`
      : "DSP :";

  const handleChange = (name, value) => {
    let current = { ...currentDsp };
    current[name] = value;
    setCurrentDsp(current);
  };

  const handleEdit = () => {
    setEnableEdit(!enableEdit);
    if (enableEdit) {
      setCurrentDsp(cachedDsp);
    } else {
      setCachedDsp(currentDsp);
    }
  };

  const handleSave = () => {
    setSaveDialogOpen(true);
  };

  const handleConfirmSave = async () => {
    let indexMessage = {
      state: {
        alertMessage: `Dsp ${currentDsp.name} updated successfully`,
        alertType: "success",
      },
    };
    try {
      await updateDsp(currentDsp.id, currentDsp.name, currentDsp.warehouse.id);
      setCachedDsp(currentDsp);
      setEnableEdit(false);
      setSaveDialogOpen(false);
      navigate(fatherRoute, indexMessage);
    } catch (error) {
      indexMessage.state = {
        alertMessage: `Dsp ${cachedDsp.name} could not be updated`,
        alertType: "error",
      };
      setCurrentDsp(cachedDsp);
      setEnableEdit(false);
      setSaveDialogOpen(false);
      navigate(fatherRoute, indexMessage);
    }
  };

  const handleConfirmDetele = async () => {
    let indexMessage = {
      state: {
        alertMessage: `Dsp ${currentDsp.name} deleted successfully`,
        alertType: "success",
      },
    };
    try {
      await deleteDsp(currentDsp.id);
      setEnableEdit(false);
      setDeleteDialogOpen(false);
      navigate(fatherRoute, indexMessage);
    } catch (error) {
      indexMessage.state = {
        alertMessage: `Dsp ${cachedDsp.name} could not be deleted`,
        alertType: "error",
      };
      setEnableEdit(false);
      setDeleteDialogOpen(false);
      navigate(fatherRoute, indexMessage);
    }
  };

  const dialogEdit = CustomDialog({
    open: saveDialogOpen,
    title: "Edit DSP",
    body: (
      <>
        <Typography variant="body1">
          Are you sure you want to save the changes?
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          Name:
        </Typography>
        <Typography variant="body1">
          {currentDsp.name !== "" ? currentDsp.name : ""}
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          Warehouse:
        </Typography>
        <Typography variant="body1">
          {currentDsp.warehouse !== null ? currentDsp.warehouse.name : ""}
        </Typography>
      </>
    ),
    confirmText: "Save",
    onConfirm: handleConfirmSave,
    onCancel: () => {
      setSaveDialogOpen(false);
    },
  });

  const dialogDelete = CustomDialog({
    open: deleteDialogOpen,
    title: "Delete DSP",
    body: (
      <>
        <Typography variant="body1">
          Are you sure you want to delete the DSP?
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          Name:
        </Typography>
        <Typography variant="body1">
          {currentDsp.name !== "" ? currentDsp.name : ""}
        </Typography>
      </>
    ),
    confirmText: "Delete",
    onConfirm: handleConfirmDetele,
    onCancel: () => {
      setDeleteDialogOpen(false);
    },
  });

  const dspForm = (
    <Grid container spacing={5}>
      {dialogEdit}
      {dialogDelete}
      <Grid
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid sx={{ order: { xs: 1 } }}>
          <Button onClick={goBack} variant="text" startIcon={<ArrowBackIcon />}>
            Go Back
          </Button>
        </Grid>
        <Grid container columnSpacing={2} sx={{ order: { xs: 2 } }}>
          {!enableEdit ? (
            <Grid>
              <Button
                onClick={handleEdit}
                variant="contained"
                color="primary"
                endIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Grid>
          ) : (
            <>
              <Grid>
                <Button
                  onClick={handleEdit}
                  variant="contained"
                  color="grey"
                  endIcon={<CancelIcon />}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid>
                <Button
                  onClick={handleSave}
                  disabled={!saveEnabled}
                  variant="contained"
                  color="success"
                  endIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </Grid>
            </>
          )}

          <Grid>
            <Button
              onClick={() => {
                setDeleteDialogOpen(true);
              }}
              variant="contained"
              color="error"
              endIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid rowSpacing={2} container xs={12}>
        <Paper sx={{ paddingRight: 2, paddingLeft: 2, width: "100%" }}>
          <Grid container>
            <Grid xs={6}>
              <TextField
                required
                sx={{ marginBottom: 0, marginTop: 2 }}
                fullWidth
                error={currentDsp?.name === ""}
                margin="normal"
                label="DSP Name"
                id="dsp-name"
                disabled={!enableEdit}
                value={currentDsp.name}
                onChange={(e) => handleChange("name", e.target.value)}
              />
            </Grid>
            <Grid xs={6}>
              <Autocomplete
                disabled={!enableEdit}
                fullWidth
                sx={{ marginBottom: 0, marginTop: 2 }}
                disablePortal
                id="warehouse-select"
                options={warehouseList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={currentDsp?.warehouse === null}
                    label="Warehouse"
                  />
                )}
                value={currentDsp.warehouse}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, value) => handleChange("warehouse", value)}
              />
            </Grid>
            <Grid xs={12}>
              <Typography sx={{ fontWeight: 600 }} variant="body1">
                Power BI group ID:
              </Typography>
              <Typography variant="body1">
                {currentDsp.powerbi_group_id}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography
                sx={{ fontWeight: 600, marginBottom: 1 }}
                variant="body1"
              >
                Reports:
              </Typography>

              <Table
                rows={currentDsp?.reports}
                columns={[
                  { field: "id", headerName: "ID" },
                  { field: "name", headerName: "Name" },
                ]}
              ></Table>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );

  const invalidId = (
    <Grid xs={12} container rowSpacing={5}>
      <Grid xs={12} justifyContent="space-between" alignItems="center">
        <Grid>
          <Typography variant="h4">Invalid DSP ID</Typography>
        </Grid>
        <Typography variant="body1">
          Please check the URL and try again. (ID: {id})
        </Typography>
        <Typography variant="body1">
          <Link to={"/"}>Go back to DSP list</Link>
        </Typography>
      </Grid>
    </Grid>
  );

  const content = currentDsp.id === "invalid" ? invalidId : dspForm;
  return (
    <AdminContainer title={pageTitle}>
      {pendingRequest || warehouseRequest || currentDsp.id === "" ? (
        <Loader open />
      ) : (
        content
      )}
    </AdminContainer>
  );
};

export default DspDetail;
