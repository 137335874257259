import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { saveUserInformation, removeUserInformation } from "./reducers/user";
import useLocalStorage from "hooks/local_storage/useLocalStorage";
import { USER_INFORMATION } from "hooks/local_storage/storageKeys";
import useAmpClient from "hooks/amp_api/useAmpApi";

const useUser = () => {
  const user = useSelector((state) => state.user.userInformation);
  const dispatch = useDispatch();

  const { getStorageItem, updateStorageItem, clearStorageItem } =
    useLocalStorage(USER_INFORMATION);

  if (user.id === "") {
    const userLocalInformation = getStorageItem();
    if (userLocalInformation.id) {
      dispatch(saveUserInformation({ userInformation: userLocalInformation }));
    }
  }

  const { setPassword, getUser, changePassword } = useAmpClient();
  const [pendingRequest, setPendingRequest] = useState(false);

  const saveUser = (email, id, role) => {
    const payload = {
      email: email,
      id: id,
      role: role,
    };
    updateStorageItem(payload);
    dispatch(saveUserInformation({ userInformation: payload }));
  };

  const removeUser = () => {
    clearStorageItem();
    dispatch(removeUserInformation());
  };

  const setUserPassword = async (password, token) => {
    setPendingRequest(true);
    try {
      const response = await setPassword(password, token);
      setPendingRequest(false);
      return response;
    } catch (error) {
      setPendingRequest(false);
      throw error;
    }
  };

  const changeUserPassword = async (password) => {
    setPendingRequest(true);
    try {
      const response = await changePassword(password);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setPendingRequest(false);
    }
  };

  const getUserInfo = async (userId) => {
    setPendingRequest(true);
    try {
      const response = await getUser(userId);
      setPendingRequest(false);
      return response;
    } catch (error) {
      setPendingRequest(false);
      throw error;
    }
  };

  const actionsUser = {
    saveUser,
    removeUser,
    setUserPassword,
    getUserInfo,
    changeUserPassword,
  };

  return {
    pendingRequest,
    user,
    actionsUser,
  };
};

export default useUser;
