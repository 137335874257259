import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login as loginAction, logout } from "./reducers/login";
import useAmpClient from "hooks/amp_api/useAmpApi";
import useUser from "./useUser";
import useLocalStorage from "hooks/local_storage/useLocalStorage";
import { USER_TOKENS } from "hooks/local_storage/storageKeys";

const useLogin = () => {
  const { actionsUser } = useUser();

  const { getStorageItem, updateStorageItem, clearStorageItem } =
    useLocalStorage(USER_TOKENS);
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const dispatch = useDispatch();

  const tokens = getStorageItem();

  if (!isLoggedIn) {
    if (tokens.access_token) {
      dispatch(loginAction());
    } else {
      dispatch(logout());
    }
  }

  const { login, recoverPassword } = useAmpClient();
  const [pendingRequest, setPendingRequest] = useState(false);

  const loginUser = async (email, password) => {
    setPendingRequest(true);
    try {
      const loginResponse = await login(email, password);

      updateStorageItem({
        access_token: loginResponse.access_token,
        refresh_token: loginResponse.refresh_token,
      });

      actionsUser.saveUser(email, loginResponse.id, loginResponse.role);
      dispatch(loginAction());
    } catch (error) {
      setPendingRequest(false);

      throw error;
    }
    setPendingRequest(false);
  };

  const validateCredentials = async (email, password) => {
    setPendingRequest(true);
    try {
      const loginResponse = await login(email, password);
      return loginResponse;
    } catch (error) {
      throw error;
    } finally {
      setPendingRequest(false);
    }
  };

  const logoutUser = () => {
    clearStorageItem();
    actionsUser.removeUser();
    dispatch(logout());
  };

  const forgotPassword = async (email) => {
    setPendingRequest(true);
    try {
      const response = await recoverPassword(email);
      setPendingRequest(false);
      return response;
    } catch (error) {
      setPendingRequest(false);
      throw error;
    }
  };

  const actionsLogin = {
    loginUser,
    logoutUser,
    forgotPassword,
    validateCredentials,
  };

  return {
    pendingRequest,
    isLoggedIn,
    actionsLogin,
  };
};

export default useLogin;
