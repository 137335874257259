import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { CheckCircle, Visibility, VisibilityOff } from "@mui/icons-material";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { useSearchParams } from "react-router-dom";

import Copyright from "components/Copyright/Copyright";
import Alerts from "components/Alerts/Alerts";
import useUser from "hooks/redux/useUser";

import { publicRoutes } from "navigation/routes";

const SetPassword = () => {
  const { actionsUser, pendingRequest } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");
  const [alert, setAlert] = useState(false);
  const [alertType, setAlertType] = useState("error");
  const [alertMessage, setAlertMessage] = useState("error");

  const [disabled, setDisabled] = useState(true);
  const [password, setPass] = useState("");
  const [repeatPass, setRepeatPass] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [repeatPassError, setRepeatPassError] = useState(false);

  const [colorEigth, setColorEigth] = useState("error");
  const [colorUpper, setColorUpper] = useState("error");
  const [colorLower, setColorLower] = useState("error");
  const [colorSpecial, setColorSpecial] = useState("error");

  const [goodPassword, setGoodPassword] = useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [showRepeatPassword, setShowRepeatPassword] = React.useState(false);
  const handleClickShowRepeatPassword = () =>
    setShowRepeatPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert(false);
  };

  useEffect(() => {
    if (password !== "") {
      if (password.length >= 8) {
        setColorEigth("success");
      } else {
        setColorEigth("error");
      }
      if (password.match(/[A-Z]/)) {
        setColorUpper("success");
      } else {
        setColorUpper("error");
      }
      if (password.match(/[a-z]/)) {
        setColorLower("success");
      } else {
        setColorLower("error");
      }
      if (password.match(/[^A-Za-z0-9]/)) {
        setColorSpecial("success");
      } else {
        setColorSpecial("error");
      }
      if (
        password.length >= 8 &&
        password.match(/[A-Z]/) &&
        password.match(/[a-z]/) &&
        password.match(/[^A-Za-z0-9]/)
      ) {
        setGoodPassword(true);
      } else {
        setGoodPassword(false);
      }
    } else {
      setColorEigth("error");
      setColorUpper("error");
      setColorLower("error");
      setColorSpecial("error");
    }

    if (
      !pendingRequest &&
      goodPassword &&
      password !== "" &&
      repeatPass !== ""
    ) {
      if (password !== repeatPass) {
        setPasswordError(true);
        setRepeatPassError(true);
      } else {
        setPasswordError(false);
        setRepeatPassError(false);
        setDisabled(false);
      }
    } else {
      setDisabled(true);
      setPasswordError(false);
      setRepeatPassError(false);
    }
  }, [pendingRequest, repeatPass, password, goodPassword]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await actionsUser.setUserPassword(password, token);

      let indexMessage = {
        state: {
          alertMessage: `Password created for user ${response.email}`,
          alertType: "success",
        },
      };
      navigate("/", indexMessage);
    } catch (error) {
      setAlertType("error");
      setAlertMessage(error.apiMsg);
      setAlert(true);
    }
  };
  const logo = (
    <img
      style={{ height: 100, marginBottom: 20 }}
      src="/amp-logo.png"
      alt="logo"
    />
  );
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {logo}
        <Typography component="h1" variant="h6">
          Create your password
        </Typography>
        <Typography variant="body1">The password must be:</Typography>
        <Box sx={{ marginTop: 1 }}>
          <List dense>
            <ListItem>
              <ListItemIcon>
                <Icon color={colorEigth}>
                  <CheckCircle />
                </Icon>
              </ListItemIcon>
              <ListItemText primary={"At least 8 characters"} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Icon color={colorUpper}>
                  <CheckCircle />
                </Icon>
              </ListItemIcon>
              <ListItemText primary={"At least 1 uppercase letter"} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Icon color={colorLower}>
                  <CheckCircle />
                </Icon>
              </ListItemIcon>
              <ListItemText primary={"At least 1 lowercase letter"} />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Icon color={colorSpecial}>
                  <CheckCircle />
                </Icon>
              </ListItemIcon>
              <ListItemText primary={"At least 1 special character"} />
            </ListItem>
          </List>
        </Box>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <FormControl margin="normal" fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              New Password
            </InputLabel>
            <OutlinedInput
              error={passwordError}
              required
              fullWidth
              name="password"
              label="New Password"
              value={password}
              onChange={(e) => setPass(e.target.value)}
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              autoComplete="current-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <FormControl margin="normal" fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Confirm Password
            </InputLabel>
            <OutlinedInput
              error={repeatPassError}
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              value={repeatPass}
              onChange={(e) => setRepeatPass(e.target.value)}
              id="outlined-adornment-confirmPassword"
              type={showRepeatPassword ? "text" : "password"}
              autoComplete="confirm-password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowRepeatPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={disabled}
            sx={{ mt: 3, mb: 2 }}
          >
            Save Password
          </Button>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
      <Alerts
        open={alert}
        handleClose={handleClose}
        alertMessage={alertMessage}
        alertType={alertType}
      />
    </Container>
  );
};

export default SetPassword;
