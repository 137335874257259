const handleAPICall = async (
  pendingRequestHandler = null,
  apiAction,
  parameters
) => {
  pendingRequestHandler && pendingRequestHandler(true);
  let result;
  let errorDetected = false;
  try {
    const response = await apiAction(...parameters);
    result = response;
  } catch (error) {
    console.log("Error during API call", JSON.stringify(error, null, 2));
    errorDetected = true;
    result = error;
  } finally {
    pendingRequestHandler && pendingRequestHandler(false);
    if (errorDetected) {
      throw result;
    } else {
      return result;
    }
  }
};

export default handleAPICall;
