import "./App.css";
import { BrowserRouter } from "react-router-dom";
import useLogin from "./hooks/redux/useLogin";
import useUser from "./hooks/redux/useUser";
import PublicRoutes from "./navigation/public";
import PrivateRoutes from "./navigation/private";

function App() {
  const { isLoggedIn } = useLogin();
  const { user } = useUser();
  return (
    <BrowserRouter>
      {!isLoggedIn ? <PublicRoutes /> : <PrivateRoutes userRole={user.role} />}
    </BrowserRouter>
  );
}

export default App;
