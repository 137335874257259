import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  systemUserList: [],
};

const systemUserSlice = createSlice({
  name: "systemUser",
  initialState: {
    ...initialState,
  },
  reducers: {
    setSystemUserList: (state, action) => {
      state.systemUserList = [...action.payload];
    },
    clearSystemUserList: (state) => {
      state.systemUserList = [];
    },
  },
});

export default systemUserSlice.reducer;
export const {
  setSystemUserList,
  clearSystemUserList,
} = systemUserSlice.actions;
