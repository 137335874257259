import { HttpClient } from "lib/HttpClient";
import jwt_decode from "jwt-decode";
import useLocalStorage from "hooks/local_storage/useLocalStorage";
import { USER_TOKENS } from "hooks/local_storage/storageKeys";
import { useDispatch } from "react-redux";
import { logout } from "hooks/redux/reducers/login";

const tokekIsExpired = (token) => {
  if (token !== undefined) {
    const decode = jwt_decode(token);
    const now = new Date().getTime() / 1000;
    const isExpired = decode.exp < now;
    return isExpired;
  }
  return true;
};

const useAmpClient = () => {
  const BASEURL = "https://backend.amngtpro.com/api/v1/";
  const {
    getStorageItem,
    updateStorageItem,
    clearStorageItem,
  } = useLocalStorage(USER_TOKENS);

  const dispatch = useDispatch();

  const requestInterceptor = async (request) => {
    const { headers } = request;
    headers["Content-Type"] = "application/json";
    headers["Accept"] = "application/json";

    const { access_token, refresh_token } = getStorageItem();
    if (access_token !== undefined) {
      const isExpired = tokekIsExpired(access_token);
      if (isExpired) {
        const response = await refreshToken(refresh_token);
        updateStorageItem({
          access_token: response.access_token,
          refresh_token: refresh_token,
        });
        headers["Authorization"] = `Bearer ${response.access_token}`;
      } else {
        headers["Authorization"] = `Bearer ${access_token}`;
      }
    }
    return request;
  };

  const apiErrorHandler = (axiosError) => {
    const { response } = axiosError;
    const error = {
      httpStatus: response.status,
      apiMsg:
        typeof response.data.detail === "string"
          ? response.data.detail
          : response.data.detail.map((item) => item.msg).join(", "),
      apiCode: 666,
    };

    if (response.status === 401) {
      clearStorageItem();
      dispatch(logout());
    }

    return Promise.reject(error);
  };

  const errorHandler = (error) => {
    return apiErrorHandler(error);
  };

  const responseInterceptor = (response) => {
    return response.data;
  };

  const client = HttpClient(
    BASEURL,
    requestInterceptor,
    responseInterceptor,
    errorHandler
  );

  const login = async (email, password) => {
    const path = "login/access-token";
    const data = {
      email: email,
      password: password,
    };
    const response = await client.post(path, data);
    const decodeToken = jwt_decode(response.access_token);

    const result = {
      role: decodeToken.roles[0],
      id: decodeToken.sub,
      ...response,
    };
    return result;
  };

  const refreshToken = async (REFRESH_TOKEN) => {
    const path = "login/refresh-token";
    const data = {
      refresh_token: REFRESH_TOKEN ? REFRESH_TOKEN : "",
    };
    const internal_client = HttpClient(BASEURL);

    const response = await internal_client.post(path, data);
    return response;
  };

  const recoverPassword = async (email) => {
    const path = "login/recover-password";
    const data = {
      email: email,
    };
    const response = await client.post(path, data);
    return response;
  };

  const setPassword = async (password, token) => {
    const path = "user/update_password";
    const data = {
      password: password,
    };

    const internalInterceptor = async (request) => {
      const { headers } = request;
      headers["Content-Type"] = "application/json";
      headers["Accept"] = "application/json";
      headers["Authorization"] = `Bearer ${token}`;
      return request;
    };

    const internal_client = HttpClient(
      BASEURL,
      internalInterceptor,
      responseInterceptor,
      errorHandler
    );

    const response = await internal_client.post(path, data);
    return response;
  };

  const changePassword = async (password) => {
    const path = "user/update_password";
    const data = {
      password: password,
    };

    const response = await client.post(path, data);
    return response;
  };

  const getUser = async (userId) => {
    const path = `user/${userId}`;
    const response = await client.get(path);
    return response;
  };

  const listUsers = async () => {
    const path = `user/`;
    const response = await client.get(path);
    return response;
  };

  const createUser = async (email, name, lastName, roles, dspId = null) => {
    const path = "user/";
    const data = {
      email: email,
      user_name: name,
      user_last_name: lastName,
      roles: roles,
    };
    if (dspId) {
      data.dsp_id = dspId;
    }
    const response = await client.post(path, data);
    return response;
  };

  const editUser = async (
    userId,
    name = null,
    lastName = null,
    dspId = null
  ) => {
    const path = `user/${userId}`;
    const data = {};
    if (name) {
      data.user_name = name;
    }
    if (lastName) {
      data.user_last_name = lastName;
    }
    if (dspId) {
      data.dsp_id = dspId;
    }
    const response = await client.put(path, data);
    return response;
  };

  const deleteUser = async (userId) => {
    const path = `user/${userId}`;
    const response = await client.del(path);
    return response;
  };

  const getReport = async (reportId) => {
    const path = `report/${reportId}`;
    const response = await client.get(path);
    return response;
  };

  const listDsps = async () => {
    const path = "dsp/";
    const response = await client.get(path);
    return response;
  };

  const createDsp = async (dspName, warehouseId) => {
    const path = "dsp/";
    const data = {
      name: dspName,
      warehouse_id: warehouseId,
    };
    const response = await client.post(path, data);
    return response;
  };

  const editDsp = async (dspId, dspName = null, warehouseId = null) => {
    const path = `dsp/${dspId}`;
    const data = {};
    if (dspName) {
      data.name = dspName;
    }
    if (warehouseId) {
      data.warehouse_id = warehouseId;
    }
    const response = await client.put(path, data);
    return response;
  };

  const deleteDsp = async (dspId) => {
    const path = `dsp/${dspId}`;
    const response = await client.del(path);
    return response;
  };

  const listWarehouses = async () => {
    const path = "warehouse/";
    const response = await client.get(path);
    return response;
  };

  const createWarehouse = async (warehouseName, stateId) => {
    const path = "warehouse/";
    const data = {
      name: warehouseName,
      state_id: stateId,
    };
    const response = await client.post(path, data);
    return response;
  };

  const editWarehouse = async (
    warehouseId,
    warehouseName = null,
    stateId = null
  ) => {
    const path = `warehouse/${warehouseId}`;
    const data = {};
    if (warehouseName) {
      data.name = warehouseName;
    }
    if (stateId) {
      data.state_id = stateId;
    }
    const response = await client.put(path, data);
    return response;
  };

  const deleteWarehouse = async (warehouseId) => {
    const path = `warehouse/${warehouseId}`;
    const response = await client.del(path);
    return response;
  };

  const listStates = async () => {
    const path = "state/";
    const response = await client.get(path);
    return response;
  };

  return {
    login,
    recoverPassword,
    setPassword,
    changePassword,
    getUser,
    listUsers,
    createUser,
    editUser,
    deleteUser,
    getReport,
    listDsps,
    createDsp,
    editDsp,
    deleteDsp,
    listWarehouses,
    createWarehouse,
    editWarehouse,
    deleteWarehouse,
    listStates,
  };
};

export default useAmpClient;
