const LocalStorage = (key) => {
  const convertToString = (data) => {
    try {
      if (typeof data === "string") {
        return data;
      } else {
        return JSON.stringify(data);
      }
    } catch (error) {
      return "";
    }
  };

  const convertFromString = (data) => {
    try {
      return JSON.parse(data);
    } catch (error) {
      return data;
    }
  };

  const getStorageItem = () => {
    const data = localStorage.getItem(key) || {};
    const converted = convertFromString(data);
    return converted;
  };

  const updateStorageItem = (data) => {
    const converted = convertToString(data);
    localStorage.setItem(key, converted);
  };

  const clearStorageItem = () => {
    localStorage.removeItem(key);
  };

  return {
    getStorageItem,
    updateStorageItem,
    clearStorageItem,
  };
};

export default LocalStorage;
