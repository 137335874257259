import React, { useEffect, useState } from "react";
import { Paper, Button, TextField, Autocomplete } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import Loader from "components/Loader/Loader";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import CustomDialog from "components/CustomDialog/CustomDialog";

import { useParams, Link, useNavigate } from "react-router-dom";

import AdminContainer from "components/AdminContainer/AdminContainer";
import useDsp from "hooks/redux/useDsp";
import useSystemUser from "hooks/redux/useSystemUser";

const SystemUserDetail = () => {
  const parentRoute = "/system_user";
  const navigate = useNavigate();
  const goBack = () => {
    navigate(parentRoute);
  };
  const { pendingRequest: dspRequest, dspList, setDspList } = useDsp();
  const { pendingRequest: userRequest, createSystemUser } = useSystemUser();

  const [saveDialogOpen, setSaveDialogOpen] = useState(false);

  const userPlaceholder = {
    email: "",
    user_name: "",
    user_last_name: "",
    dsp: null,
    roles: null,
  };

  const roles = [
    { id: "admin", name: "Admin" },
    { id: "regular_user", name: "Regular User" },
  ];
  const [currentUser, setCurrentUser] = useState(userPlaceholder);

  useEffect(() => {
    async function initializeData() {
      await setDspList();
    }
    if (dspList.length === 0) {
      initializeData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageTitle = "Create User";

  const handleChange = (name, value) => {
    let current = { ...currentUser };
    current[name] = value;
    setCurrentUser(current);
  };

  const handleSave = () => {
    setSaveDialogOpen(true);
  };

  const handleConfirmSave = async () => {
    let indexMessage = {
      state: {
        alertMessage: `User ${currentUser.email} created successfully`,
        alertType: "success",
      },
    };
    const saveDsp = currentUser.roles.id === "regular_user";
    const user = { ...currentUser };
    user.roles = [user.roles.id];
    const saveProps = [
      user.email,
      user.user_name,
      user.user_last_name,
      user.roles,
    ];
    if (saveDsp) {
      saveProps.push(user.dsp.id);
    }
    try {
      await createSystemUser(...saveProps);
      setSaveDialogOpen(false);
      navigate(parentRoute, indexMessage);
    } catch (error) {
      indexMessage.state = {
        alertMessage: `User ${currentUser.email} could not be created`,
        alertType: "error",
      };
      setSaveDialogOpen(false);
      navigate(parentRoute, indexMessage);
    }
  };

  const userHasDSP =
    currentUser.roles !== null && currentUser.roles.id !== "admin";

  const dialogEdit = CustomDialog({
    open: saveDialogOpen,
    title: "Save User",
    body: (
      <>
        <Typography variant="body1">
          Are you sure you want to save the changes?
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          Email:
        </Typography>
        <Typography variant="body1">
          {currentUser.email !== "" ? currentUser.email : ""}
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          Role:
        </Typography>
        <Typography variant="body1">
          {currentUser.roles !== null ? currentUser.roles.name : ""}
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          Name:
        </Typography>
        <Typography variant="body1">
          {currentUser.user_name !== "" ? currentUser.user_name : ""}
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          Last Name:
        </Typography>
        <Typography variant="body1">
          {currentUser.user_last_name !== "" ? currentUser.user_last_name : ""}
        </Typography>
        {userHasDSP && (
          <>
            {" "}
            <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
              DSP:
            </Typography>
            <Typography variant="body1">
              {currentUser.dsp !== null ? currentUser.dsp.name : ""}
            </Typography>
          </>
        )}
      </>
    ),
    confirmText: "Save",
    onConfirm: handleConfirmSave,
    onCancel: () => {
      setSaveDialogOpen(false);
    },
  });

  const saveEnabled =
    currentUser.email !== "" &&
    currentUser.roles !== null &&
    currentUser.user_name !== "" &&
    currentUser.user_last_name !== null &&
    ((userHasDSP && currentUser.dsp !== null) || !userHasDSP);

  const userForm = (
    <Grid container spacing={5}>
      {dialogEdit}
      <Grid
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid sx={{ order: { xs: 1 } }}>
          <Button onClick={goBack} variant="text" startIcon={<ArrowBackIcon />}>
            Go Back
          </Button>
        </Grid>
        <Grid container columnSpacing={2} sx={{ order: { xs: 2 } }}>
          <Grid>
            <Button
              disabled={!saveEnabled}
              onClick={handleSave}
              variant="contained"
              color="success"
              endIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid rowSpacing={4} container xs={12}>
        <Paper sx={{ paddingRight: 2, paddingLeft: 2, width: "100%" }}>
          <Grid container>
            <Grid xs={6}>
              <Autocomplete
                fullWidth
                sx={{ marginBottom: 0, marginTop: 2 }}
                disablePortal
                id="role-select"
                options={roles}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={currentUser?.roles === null}
                    label="Roles"
                  />
                )}
                value={currentUser.roles}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, value) => handleChange("roles", value)}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                required
                sx={{ marginBottom: 0, marginTop: 2 }}
                fullWidth
                error={currentUser?.email === ""}
                margin="normal"
                label="Email"
                id="email"
                value={currentUser.email}
                onChange={(e) => handleChange("email", e.target.value)}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                required
                sx={{ marginBottom: 0, marginTop: 2 }}
                fullWidth
                error={currentUser?.user_name === ""}
                margin="normal"
                label="Name"
                id="name"
                value={currentUser.user_name}
                onChange={(e) => handleChange("user_name", e.target.value)}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                required
                sx={{ marginBottom: 0, marginTop: 2 }}
                fullWidth
                error={currentUser?.user_last_name === ""}
                margin="normal"
                label="Last Name"
                id="name"
                value={currentUser.user_last_name}
                onChange={(e) => handleChange("user_last_name", e.target.value)}
              />
            </Grid>
            {userHasDSP && (
              <Grid xs={6}>
                <Autocomplete
                  fullWidth
                  sx={{ marginBottom: 0, marginTop: 2 }}
                  disablePortal
                  id="dsp-select"
                  options={dspList}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={currentUser?.dsp === null}
                      label="DSP"
                    />
                  )}
                  value={currentUser.dsp}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(e, value) => handleChange("dsp", value)}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );

  const content = userForm;
  return (
    <AdminContainer title={pageTitle}>
      {dspRequest || userRequest ? <Loader open /> : content}
    </AdminContainer>
  );
};

export default SystemUserDetail;
