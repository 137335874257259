import { useState } from "react";
import useAmpClient from "hooks/amp_api/useAmpApi";
import handleAPICall from "helpers/handleAPICall";
import { setDspList as setDspListAction, clearDspList } from "./reducers/dsp";
import { useDispatch, useSelector } from "react-redux";

const useDsp = () => {
  const apiClient = useAmpClient();
  const [pendingRequest, setPendingRequest] = useState(false);
  const dispatch = useDispatch();
  const dspList = useSelector((state) => state.dsp.dspList);

  const setDspList = async () => {
    setPendingRequest(true);
    try {
      const dspList = await handleAPICall(null, apiClient.listDsps, []);
      dispatch(setDspListAction(dspList));
      setPendingRequest(false);
    } catch (error) {
      setPendingRequest(false);
      throw error;
    }
  };

  const createDsp = async (dspName, warehouseId) => {
    return await handleAPICall(setPendingRequest, apiClient.createDsp, [
      dspName,
      warehouseId,
    ]);
  };

  const updateDsp = async (dspId, dspName = null, warehouseId = null) => {
    return await handleAPICall(setPendingRequest, apiClient.editDsp, [
      dspId,
      dspName,
      warehouseId,
    ]);
  };

  const deleteDsp = async (dspId) => {
    return await handleAPICall(setPendingRequest, apiClient.deleteDsp, [dspId]);
  };

  const clearState = () => {
    dispatch(clearDspList());
  };

  return {
    pendingRequest,
    dspList,
    setDspList,
    createDsp,
    updateDsp,
    deleteDsp,
    clearState,
  };
};

export default useDsp;
