import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./reducers/login";
import userReducer from "./reducers/user";
import warehouseReducer from "./reducers/warehouse";
import dspReducer from "./reducers/dsp";
import systemUserReducer from "./reducers/systemUser";
import usaStateReducer from "./reducers/usaState";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    user: userReducer,
    warehouse: warehouseReducer,
    dsp: dspReducer,
    systemUser: systemUserReducer,
    usaState: usaStateReducer,
  },
});
