import * as React from "react";
import Box from "@mui/material/Box";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

const PowerBiContainer = ({
  embedUrl,
  embedToken,
  reportId,
  reportReference,
}) => {
  return (
    <Box sx={{ marginTop: 4, height: "100%" }}>
      <PowerBIEmbed
        style={{ height: "80vmin" }}
        embedConfig={{
          type: "report", // Supported types: report, dashboard, tile, visual and qna
          id: reportId,
          embedUrl: embedUrl,
          accessToken: embedToken,
          tokenType: models.TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false,
              },
            },
            background: models.BackgroundType.Default,
          },
        }}
        eventHandlers={
          new Map([
            ["loaded", function () {}],
            ["rendered", function () {}],
            [
              "error",
              function (event) {
                console.log(event.detail);
              },
            ],
          ])
        }
        cssClassName={"PowerBI"}
        getEmbeddedComponent={(embeddedReport) => {
          reportReference.current = embeddedReport;
        }}
      />
    </Box>
  );
};

export default PowerBiContainer;
