import React, { useEffect, useState } from "react";
import { Paper, Button, TextField, Autocomplete } from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import Loader from "components/Loader/Loader";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import CustomDialog from "components/CustomDialog/CustomDialog";

import { useParams, Link, useNavigate } from "react-router-dom";

import AdminContainer from "components/AdminContainer/AdminContainer";
import useWarehouse from "hooks/redux/useWarehouse";
import useUsaState from "hooks/redux/useUsaState";
import { setUsaStateList } from "hooks/redux/reducers/usaState";

const WarehouseDetail = () => {
  const fatherRoute = "/warehouse";
  const navigate = useNavigate();
  const goBack = () => {
    navigate(fatherRoute);
  };
  const {
    pendingRequest: warehouseRequest,
    setWarehouseList,
    updateWarehouse,
    deleteWarehouse,
    warehouseList,
  } = useWarehouse();
  const { pendingRequest: usaStateRequest, usaStateList } = useUsaState();

  const [enableEdit, setEnableEdit] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const warehousePlaceholder = {
    id: "",
    name: "",
    state: null,
  };

  const [currentWarehouse, setCurrentWarehouse] = useState(
    warehousePlaceholder
  );

  const [cachedWarehouse, setCachedWarehouse] = useState(warehousePlaceholder);

  const { id } = useParams();

  useEffect(() => {
    async function initializeData() {
      await setWarehouseList();
    }
    async function initializeUsaState() {
      await setUsaStateList();
    }
    if (warehouseList.length === 0) {
      initializeData();
    }
    if (usaStateList.length === 0) {
      initializeUsaState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (warehouseList.length > 0) {
      const warehouse = warehouseList.find((warehouse) => warehouse.id === id);
      if (warehouse !== undefined) {
        setCurrentWarehouse(warehouse);
        setCachedWarehouse(warehouse);
      } else {
        setCurrentWarehouse({
          id: "invalid",
          name: "",
          state: null,
        });
      }
    }
  }, [warehouseList, id]);

  const saveEnabled =
    currentWarehouse.name !== "" && currentWarehouse.state !== null;

  const pageTitle =
    currentWarehouse !== undefined && currentWarehouse.name
      ? `Warehouse : ${cachedWarehouse.name}`
      : "Warehouse :";

  const handleChange = (name, value) => {
    let current = { ...currentWarehouse };
    current[name] = value;
    setCurrentWarehouse(current);
  };

  const handleEdit = () => {
    setEnableEdit(!enableEdit);
    if (enableEdit) {
      setCurrentWarehouse(cachedWarehouse);
    } else {
      setCachedWarehouse(currentWarehouse);
    }
  };

  const handleSave = () => {
    setSaveDialogOpen(true);
  };

  const handleConfirmSave = async () => {
    let indexMessage = {
      state: {
        alertMessage: `Warehouse ${currentWarehouse.name} updated successfully`,
        alertType: "success",
      },
    };
    try {
      await updateWarehouse(
        currentWarehouse.id,
        currentWarehouse.name,
        currentWarehouse.state.id
      );
      setCachedWarehouse(currentWarehouse);
      setEnableEdit(false);
      setSaveDialogOpen(false);
      navigate(fatherRoute, indexMessage);
    } catch (error) {
      indexMessage.state = {
        alertMessage: `Warehouse ${cachedWarehouse.name} could not be updated`,
        alertType: "error",
      };
      setCurrentWarehouse(cachedWarehouse);
      setEnableEdit(false);
      setSaveDialogOpen(false);
      navigate(fatherRoute, indexMessage);
    }
  };

  const handleConfirmDetele = async () => {
    let indexMessage = {
      state: {
        alertMessage: `Warehouse ${currentWarehouse.name} deleted successfully`,
        alertType: "success",
      },
    };
    try {
      await deleteWarehouse(currentWarehouse.id);
      setEnableEdit(false);
      setDeleteDialogOpen(false);
      navigate(fatherRoute, indexMessage);
    } catch (error) {
      indexMessage.state = {
        alertMessage: `Warehouse ${cachedWarehouse.name} could not be deleted`,
        alertType: "error",
      };
      setEnableEdit(false);
      setDeleteDialogOpen(false);
      navigate(fatherRoute, indexMessage);
    }
  };

  const dialogEdit = CustomDialog({
    open: saveDialogOpen,
    title: "Edit Warehouse",
    body: (
      <>
        <Typography variant="body1">
          Are you sure you want to save the changes?
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          Name:
        </Typography>
        <Typography variant="body1">
          {currentWarehouse.name !== "" ? currentWarehouse.name : ""}
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          State:
        </Typography>
        <Typography variant="body1">
          {currentWarehouse.state !== null ? currentWarehouse.state.name : ""}
        </Typography>
      </>
    ),
    confirmText: "Save",
    onConfirm: handleConfirmSave,
    onCancel: () => {
      setSaveDialogOpen(false);
    },
  });

  const dialogDelete = CustomDialog({
    open: deleteDialogOpen,
    title: "Delete Warehouse",
    body: (
      <>
        <Typography variant="body1">
          Are you sure you want to delete the Warehouse?
        </Typography>
        <Typography sx={{ marginTop: 1, fontWeight: 600 }} variant="body1">
          Name:
        </Typography>
        <Typography variant="body1">
          {currentWarehouse.name !== "" ? currentWarehouse.name : ""}
        </Typography>
      </>
    ),
    confirmText: "Delete",
    onConfirm: handleConfirmDetele,
    onCancel: () => {
      setDeleteDialogOpen(false);
    },
  });
  const warehouseForm = (
    <Grid container spacing={5}>
      {dialogEdit}
      {dialogDelete}
      <Grid
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid sx={{ order: { xs: 1 } }}>
          <Button onClick={goBack} variant="text" startIcon={<ArrowBackIcon />}>
            Go Back
          </Button>
        </Grid>
        <Grid container columnSpacing={2} sx={{ order: { xs: 2 } }}>
          {!enableEdit ? (
            <Grid>
              <Button
                onClick={handleEdit}
                variant="contained"
                color="primary"
                endIcon={<EditIcon />}
              >
                Edit
              </Button>
            </Grid>
          ) : (
            <>
              <Grid>
                <Button
                  onClick={handleEdit}
                  variant="contained"
                  color="grey"
                  endIcon={<CancelIcon />}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid>
                <Button
                  onClick={handleSave}
                  disabled={!saveEnabled}
                  variant="contained"
                  color="success"
                  endIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </Grid>
            </>
          )}

          <Grid>
            <Button
              onClick={() => {
                setDeleteDialogOpen(true);
              }}
              variant="contained"
              color="error"
              endIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid rowSpacing={2} container xs={12}>
        <Paper sx={{ paddingRight: 2, paddingLeft: 2, width: "100%" }}>
          <Grid container>
            <Grid xs={6}>
              <TextField
                required
                sx={{ marginBottom: 0, marginTop: 2 }}
                fullWidth
                error={currentWarehouse?.name === ""}
                margin="normal"
                label="Warehouse Name"
                id="warehouse-name"
                disabled={!enableEdit}
                value={currentWarehouse.name}
                onChange={(e) => handleChange("name", e.target.value)}
              />
            </Grid>
            <Grid xs={6}>
              <Autocomplete
                disabled={!enableEdit}
                fullWidth
                sx={{ marginBottom: 0, marginTop: 2 }}
                disablePortal
                id="usastate-select"
                options={usaStateList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={currentWarehouse?.state === null}
                    label="State"
                  />
                )}
                value={currentWarehouse.state}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, value) => handleChange("state", value)}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );

  const invalidId = (
    <Grid xs={12} container rowSpacing={5}>
      <Grid xs={12} justifyContent="space-between" alignItems="center">
        <Grid>
          <Typography variant="h4">Invalid Warehouse ID</Typography>
        </Grid>
        <Typography variant="body1">
          Please check the URL and try again. (ID: {id})
        </Typography>
        <Typography variant="body1">
          <Link to={"/"}>Go back to Warehouse list</Link>
        </Typography>
      </Grid>
    </Grid>
  );

  const content = currentWarehouse.id === "invalid" ? invalidId : warehouseForm;
  return (
    <AdminContainer title={pageTitle}>
      {warehouseRequest || usaStateRequest || currentWarehouse.id === "" ? (
        <Loader open />
      ) : (
        content
      )}
    </AdminContainer>
  );
};

export default WarehouseDetail;
