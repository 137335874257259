import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  usaStateList: [],
};

const usaStateSlice = createSlice({
  name: "usaState",
  initialState: {
    ...initialState,
  },
  reducers: {
    setUsaStateList: (state, action) => {
      state.usaStateList = [...action.payload];
    },
    clearUsaStateList: (state) => {
      state.usaStateList = [];
    },
  },
});

export default usaStateSlice.reducer;
export const { setUsaStateList, clearUsaStateList } = usaStateSlice.actions;
