import { Route } from "react-router-dom";
import BusinessIcon from "@mui/icons-material/Business";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import GroupsIcon from "@mui/icons-material/Groups";

import SignIn from "views/Public/SignIn/SignIn";
import ForgotPassword from "views/Public/ForgotPassword/ForgotPassword";
import SetPassword from "views/Public/SetPassword/SetPassword";

import ChangePassword from "views/Private/ChangePassword/ChangePassword";

import Dashboard from "views/Private/RegularUser/Dashboard/Dashboard";

import Dsp from "views/Private/Admin/Dsp/Index";
import Warehouses from "views/Private/Admin/Warehouse/Index";
import SystemUsers from "views/Private/Admin/SystemUser/Index";
import DspDetail from "views/Private/Admin/Dsp/detail";
import DspCreate from "views/Private/Admin/Dsp/create";
import SystemUserDetail from "views/Private/Admin/SystemUser/detail";
import SystemUserCreate from "views/Private/Admin/SystemUser/create";
import WarehouseDetail from "views/Private/Admin/Warehouse/detail";
import WarehouseCreate from "views/Private/Admin/Warehouse/create";

export const routeMapper = (routeArray) => {
  const routes = routeArray.map((route, index) => {
    return <Route key={index} path={route.path} element={route.component} />;
  });
  return routes;
};

export const publicRoutes = [
  {
    path: "/",
    component: <SignIn />,
  },
  {
    path: "forgot_password",
    component: <ForgotPassword />,
  },
  {
    path: "set_password",
    component: <SetPassword />,
  },
];

export const privateRoutes = [
  {
    path: "change_password",
    component: <ChangePassword />,
  },
];

export const regularUserRoutes = [
  {
    path: "/",
    component: <Dashboard />,
  },
];

export const adminRoutes = [
  {
    path: "/",
    component: <Dsp />,
    icon: <BusinessIcon />,
    title: "Dsp",
  },
  {
    path: "/system_user",
    component: <SystemUsers />,
    icon: <GroupsIcon />,
    title: "System Users",
  },
  {
    path: "/warehouse",
    component: <Warehouses />,
    icon: <WarehouseIcon />,
    title: "Warehouses",
  },
];

export const adminDetailRoutes = [
  {
    path: "/dsp/:id",
    component: <DspDetail />,
  },
  {
    path: "/dsp/create",
    component: <DspCreate />,
  },
  {
    path: "/system_user/:id",
    component: <SystemUserDetail />,
  },
  {
    path: "/system_user/create",
    component: <SystemUserCreate />,
  },
  {
    path: "/warehouse/:id",
    component: <WarehouseDetail />,
  },
  {
    path: "/warehouse/create",
    component: <WarehouseCreate />,
  },
];

export const routesToDict = (routeArray) => {
  return routeArray.reduce((obj, item) => ((obj[item.path] = item), obj), {});
};
