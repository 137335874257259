import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  warehouseList: [],
};

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState: {
    ...initialState,
  },
  reducers: {
    setWarehouseList: (state, action) => {
      state.warehouseList = [...action.payload];
    },
    clearWarehouseList: (state) => {
      state.warehouseList = [];
    },
  },
});

export default warehouseSlice.reducer;
export const { setWarehouseList, clearWarehouseList } = warehouseSlice.actions;
